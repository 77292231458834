import { getCookie } from '@/lib/auth-cookies-new';
import { AuthToken } from '@/lib/auth-token';
import SentryError from '@/lib/Sentry';

export const getBaseUrl = (url: string) => {
  try {
    const parsedURL = new URL(url);
    const isProd = parsedURL.hostname.includes(`unisetu.com`);
    if (isProd) {
      const protocol = parsedURL.protocol;
      const token = getCookie(null, `token`);
      const authToken = new AuthToken(token);
      // Get the hostname (e.g., 'www.example.com')
      let hostname;
      if (parsedURL.hostname === `unisetu.com`) {
        // hostname = `${authToken.tenant ?? 'patiala'}.unisetu.com`;
        hostname = `${authToken.tenant}.unisetu.com`;
      } else {
        hostname = `${authToken.tenant}${parsedURL.hostname.substring(
          parsedURL.hostname.indexOf(`.`),
        )}`;
      }
      // Get the port if specified (e.g., '8080', otherwise it will be an empty string)
      const port = parsedURL.port;
      let baseUrl = `${protocol}//${hostname}`;
      if (port) {
        baseUrl += `:${port}`;
      }
      // Outputs something like 'https://www.example.com' or 'https://www.example.com:8080'
      return baseUrl;
    } else {
      return url;
    }
  } catch (err) {
    new SentryError(`Redirection Failed`, err);
    console.error(err);
    return url;
  }
};

export const getSlugAfterHostname = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    let slug = parsedUrl?.pathname?.slice(1); // Remove the leading '/'
    if (parsedUrl?.search) {
      slug += parsedUrl?.search; // add query params, if any
    }
    return slug;
  } catch (error) {
    console.error(`Invalid URL:`, error);
    return ``; // Return an empty string or handle the error as appropriate
  }
};

export const validateIfStudentIsLoggedIn = (auth: AuthToken) => {
  if (!auth) return null;
  const isStudentLoggedIn =
    auth?.decodedToken?.user_id?.roles?.includes(`CSP_STUDENT`);
  return isStudentLoggedIn;
};
